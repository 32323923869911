#projects {
    background-color: rgba(255, 255, 255, 0.815);
    color: #000000;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text */
}

#projects h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
}

#projects ul {
    list-style-type: none;
    padding: 0;
}

#projects li {
    
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text */
}

#projects li img {
    width: 50%; /* Set width to 50% of the screen */
    height: auto;
    margin-bottom: 20px; /* Add margin between image and text */
    border-radius: 10px;
}

#projects li .image-container {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center images horizontally */
}

#projects li .image-container img {
    width: 40%; /* Set width to 40% of the screen */
    height: auto;
    margin-right: 20px; /* Add margin between images */
    border-radius: 10px;
}

#projects li div {
    flex-grow: 1;
    width: 100%; /* Set width to 100% of the container */
}

#projects li h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
}

#projects li p {
    margin-bottom: 10px;
}

#projects li a {
    color: #66ccff;
    text-decoration: none;
    transition: color 0.3s ease;
}

#projects li a:hover {
    color: #fff;
}
@media (max-width: 768px) {
    #projects li img {
        width: 100%; /* Set width to 100% on smaller screens */
        margin-right: 0; /* Remove margin on smaller screens */
    }
}
