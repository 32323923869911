/* cookiebanner.css */
.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 15px;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.button-container {
  margin-top: 10px;
}

.button-container button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
}

.button-container button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .cookie-banner {
    font-size: 12px;
  }
}
