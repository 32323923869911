/* styles/skills.css */

#skills {
    background-color: #2c3e50; /* Dark green background */
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
}

#skills h2 {
    font-size: 2rem; /* Increased font size */
    margin-bottom: 10px;
}

.skill-tree ul {
    list-style-type: none;
    padding: 0;
}

.skill-tree li {
    margin-bottom: 5px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.skill-tree li:hover {
    color: #007bff; /* Blue color on hover */
}

.skill-tree li::before {
    content: "\2022";
    color: #007bff;
    font-size: 1.2em;
    margin-right: 10px;
}
