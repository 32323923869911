/* styles/resume.css */

/* Main container */
.main {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  /* Resume section */
  #resume {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
  }
  
  /* Contact info */
  .contact-info {
    margin-bottom: 20px;
  }
  
  /* Section headings */
  .section h3 {
    margin-bottom: 10px;
  }
  
  /* Experience section */
  .section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .section ul li {
    margin-bottom: 20px;
  }
  
  .section ul li strong {
    font-weight: bold;
  }
  
  .section ul li span {
    display: block;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  /* Education section */
  .section ul li p {
    margin-top: 5px;
  }
  
  /* References section */
  .references ul {
    list-style-type: none;
    padding: 0;
  }
  
  .references ul li {
    margin-bottom: 10px;
  }
  