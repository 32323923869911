/* styles/about.css */

#about {
    background-color: #333;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
}

#about h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

#about p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
}
