/* styles/header.css */

header {
    background-color: #333;
    color: #fff;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 999;
    margin: 0;
    background-image: url('https://images.unsplash.com/photo-1519774097905-a937f6c552e6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
}

header h1 {
    font-size: 3.5rem;
    margin: 0;
    animation: fadeInDown 1s ease-out;
}

header p {
    font-size: 1.5rem;
    margin-bottom: 1em;
    animation: fadeInUp 1s ease-out;
}

nav {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.5em;
    border-radius: 10px;
    animation: fadeInUp 2s ease-out;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    display: flex; /* Display flex for larger screens */
    justify-content: space-around;
    align-items: center;
}

nav li {
    margin-right: 1rem;
}

nav a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 15px 25px;
    text-decoration: none;
    border-radius: 10px;
    font-size: 1.5rem;
    transition: background-color 0.3s ease;
}

nav a:hover {
    background-color: rgba(0, 0, 255, 0.6);
    opacity: 0.8;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive design example */
@media (max-width: 768px) {
    header h1 {
        font-size: 2rem; /* Adjusted font size for smaller screens */
    }

    header {
        position: relative; /* Added relative positioning for smaller screens */
    }

    nav ul {
        display: block; /* Change to block for smaller screens */
        text-align: center; /* Align items to center on smaller screens */
    }

    nav li {
        margin-right: 0; /* Remove margin-right for smaller screens */
        margin-bottom: 10px; /* Add margin bottom for spacing */
    }

    nav a {
        padding: 0; /* Adjusted padding for smaller screens */
    }
}

